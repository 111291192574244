<template>
  <div class="webWrap homeCont1">
    <div class="webCont">
      <div class="indexTitle">
        <p>以产品丰富教育内涵</p>
        <span></span>
      </div>
      <div class="btns">
        <div class="line"></div>
        <div class="btn" :class="index == currentTab ? 'on' : ''" v-for="(item, index) in btnsCont">{{item.text}}</div>
      </div>
      <div class="tabCont clearfix" style="display:block">
        <img src="static/lvya/home/index-cont1-img1.png" alt="" class="img">
        <div class="text">
          <div class="title" @click="toLinkNew('/schoolFzpt')">学校发展</div>
          <div class="des">站在教育质量保障与发展视角，以新技术新理念探索教育教学和治理新模式，激发学校发展新活力，实现发展方式从规模发展向内涵发展的转变。以学校信息化创新平台为载体，将“三全育人”“五育并举”有机融合，构筑立德树人体系，探索卓越人才培养的教育改革路径和方式。</div>
          <div class="links">
            <div class="link" @click="toLinkNew('/developmentPlanning')">
              <img src="static/lvya/home/icon-fzgh.png" alt="">
              <p>发展规划</p>
            </div>
            <div class="link" @click="toLinkNew('/studentGrowth')">
              <img src="static/lvya/home/icon-xscz.png" alt="">
              <p>学生成长</p>
            </div>
            <div class="link" @click="toLinkNew('/teacherDevelopment')">
              <img src="static/lvya/home/icon-jsfz.png" alt="">
              <p>教师发展</p>
            </div>
          </div>
          <div class="btn_div">
            <button @click="toLinkNew('/schoolFzpt')">了解更多</button>
            <button @click="yyys()">预约演示</button>
          </div>
        </div>
      </div>
      <div class="tabCont clearfix">
        <img src="static/lvya/home/index-cont1-img2.png" alt="" class="img">
        <div class="text">
          <div class="title" @click="toLinkNew('/jzjx')">精准教学</div>
          <div class="des">常态化采集学生作业、测评、课堂学情数据，深度挖掘数据价值，实现大数据驱动学校科学管、教师精准教、学生个性学，帮助学校管理者实时掌握学情动态，优化教学与管理策略，稳步提升学校整体教学质量。</div>
          <div class="links">
            <div class="link noLink">
              <img src="static/lvya/home/icon-jxpt.png" alt="">
              <p>教学平台</p>
            </div>
            <div class="link noLink">
              <img src="static/lvya/home/icon-zyxt.png" alt="">
              <p>作业系统</p>
            </div>
            <div class="link noLink">
              <img src="static/lvya/home/icon-ksxt.png" alt="">
              <p>考试系统</p>
            </div>
            <div class="link noLink" style="margin-top: 40px;">
              <img src="static/lvya/home/icon-tkxt.png" alt="">
              <p>题库系统</p>
            </div>
            <div class="link noLink" style="margin-top: 40px;">
              <img src="static/lvya/home/icon-zhkt.png" alt="">
              <p>智慧课堂</p>
            </div>
            <div class="link noLink" style="margin-top: 40px;">
              <img src="static/lvya/home/icon-xyzljc.png" alt="">
              <p>学业质量检测与诊断</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tabCont clearfix">
        <img src="static/lvya/home/index-cont1-img3.png" alt="" class="img">
        <div class="text">
          <div class="title" @click="toLinkNew('/zhgl')">智慧管理</div>
          <div class="des">基于现代物联网技术，依托智慧管理软件平台和智能硬件，打造线上、线下一体化智慧校园服务，全面解决学校在办公、招生、后勤管理等方面的管理需求，有效降低学校管理成本，提升学校管理效率，实现学校管理自动化、高效化与智能化。</div>
          <div class="links">
            <div class="link noLink">
              <img src="static/lvya/home/icon-bggl.png" alt="">
              <p>校务办公</p>
            </div>
            <div class="link noLink">
              <img src="static/lvya/home/icon-zsgl.png" alt="">
              <p>招生管理</p>
            </div>
            <div class="link noLink">
              <img src="static/lvya/home/icon-zcgl.png" alt="">
              <p>后勤管理</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tabCont clearfix">
        <img src="static/lvya/home/index-cont1-img4.png" alt="" class="img">
        <div class="text">
          <div class="title" @click="toLinkNew('/ddmpt')">低代码平台</div>
          <div class="des">聚焦教育场景，把教育应用场景的设计权和运营权充分赋能给学校； 学校结合自身办学理念，量身定制应用场景，形成以用户为中心的教育应用闭环。利用可视化技术，让不同经验水平的开发人员也能通过图形化界面，轻松搭建各类学校应用。</div>
          <div class="links" style="justify-content:flex-start">
            <div class="link" @click="toLinkNew('/ddmpt')">
              <img src="static/lvya/home/icon-cjgl.png" alt="">
              <p>低代码平台</p>
            </div>
            <div class="link" @click="hrefUrlCjdt()">
              <img src="static/lvya/home/icon-gzyq.png" alt="">
              <p>场景大厅</p>
            </div>
            <div class="link"></div>
          </div>
        </div>
      </div>
      <div class="tabCont clearfix">
        <img src="static/lvya/home/index-cont1-img5.png" alt="" class="img">
        <div class="text">
          <div class="title"  @click="toLinkNew('/dataCenter')">基础平台</div>
          <div class="des">支撑服务共享与数据交换平台，实现中心平台与应用平台互联互通，提供平台标准汇集应用平台数据，对外提供统一数据服务。强大的数据分析能力，全方位展示学校信息，助力学校智慧管理、精准决策。</div>
          <div class="links">
            <div class="link" @click="toLinkNew('/businessCenter')">
              <img src="static/lvya/home/icon-ywzt.png" alt="">
              <p>业务中台</p>
            </div>
            <div class="link" @click="toLinkNew('/dataCenter')">
              <img src="static/lvya/home/icon-sjzt.png" alt="">
              <p>数据中台</p>
            </div>
            <div class="link" @click="toLinkNew('/schoolBigData')">
              <img src="static/lvya/home/icon-sjjsc.png" alt="">
              <p>数据驾驶舱</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="360px"
      class="tc_div">
      <div>
        <img src="static/lvya/schoolFzpt/index/yyys4.png" alt="">
        <p>微信扫码获取更多产品资料</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      currentTab: 0,
      btnsCont: [
        { id: 0, text: '学校发展' },
        { id: 1, text: '精准教学' },
        { id: 2, text: '智慧管理' },
        { id: 3, text: '低代码平台' },
        { id: 4, text: '基础平台' },
      ]
    }
  },
  mounted() {
    $('.btns .btn').click(function() {
      let index = $('.homeCont1 .btns .btn').index(this)
      console.log(index)
      $(this).addClass('on').siblings().removeClass('on')
      $('.tabCont').hide().removeClass('animate__animated animate__fadeInUp')
      $('.tabCont').eq(index).show().addClass('animate__animated animate__fadeInUp')
    })
  },
  methods: {
    hrefUrlCjdt() {
      window.open('https://eos.lvya.org/admin/#/publicSceneHall')
    },
    toLink(path) {
      this.$router.push({
        path
      })
    },
    toLinkNew(path) {
      let routeData = this.$router.resolve({ path })
      window.open(routeData.href, '_blank')
    },
    yyys() {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
  .homeCont1{
    padding-bottom: 50px;
    background: url(../../../../../public/static/lvya/home/index-cont1Bg.jpg) no-repeat bottom center;
    background-size: 100%;
    overflow: hidden;
  }
  .btns{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    position: relative;
    .line{
      position: absolute;
      top: 23px;
      left: 0;
      border: 1px dashed #D4D5DA;
      width: 100%;
    }
    .btn{
      width: 155px;
      height: 48px;
      line-height: 48px;
      background: #F0F4F7;
      border-radius: 24px;
      font-size: 20px;
      font-weight: 400;
      color: #73869E;
      cursor: pointer;
      text-align: center;
      position: relative;
      z-index: 2;
      transition: all 0.5s;
      &.on, &:hover{
        transition: all 0.5s;
        background: #0DC579;
        box-shadow: 0px 7px 21px 0px rgba(0,182,107,0.4100);
        color: #ffffff;
      }
    }
  }
  .tabCont{
    position: relative;
    display: none;
    .img{
      float: left;
      transform: translateX(-100px);
    }
    .text{
      position: absolute;
      width:400px;
      top: 80px;
      right: 0;
      transform: translateX(20px);
    }
    .title{
      font-size: 26px;
      font-weight: 600;
      color: #222222;
      margin-bottom: 25px;
      cursor: pointer;
      background: url(../../../../../public/static/lvya/more.png) no-repeat right center;
      &:hover{
        transition: all 0.5s;
        color: #0DC579;
        background: url(../../../../../public/static/lvya/more-on.png) no-repeat right center;
      }
    }
    .des{
      font-size: 16px;
      font-weight: 400;
      color: #696969;
      line-height: 39px;
      text-align: justify;
    }
    .links{
      display: flex;
      margin-top: 30px;
      justify-content: space-between;
      flex-wrap: wrap;
      .link{
        width: 30%;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #585858;
        margin-top: 20px;
        position: relative;
        cursor: pointer;
        top: 0;
        transition: all 0.5s;
        &:hover{
          top: -10px;
          transition: all 0.5s;
        }
      }

      .noLink{
        cursor: text!important;
      }
    }
    .btn_div{
      display: flex;
      justify-content: center;
      button{
        width: 110px;
        height: 35px;
        background: #0DC579;
        box-shadow: 0px 7px 21px 0px rgba(0,182,107,0.4100);
        border-radius: 5px;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
        margin-top: 40px;
        &:hover{
          opacity: 0.8;
        }
      }
      button:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
  .tc_div{
    text-align: center;
    p{
      margin-top: 20px;
    }
  }
</style>
