<template>
  <div class="webWrap banner">
    <div class="swiper1">
        <swiper :options="swiperOption1">
          <swiper-slide>
            <img src="static/lvya/banner/01.png?v=1.0" alt="">
            <button @click="toLink()">预约演示</button>
          </swiper-slide>
          <swiper-slide>
            <img src="static/lvya/banner/02.png?v=1.0" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="static/lvya/banner/03.png?v=1.0" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="static/lvya/banner/04.png?v=1.0" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="static/lvya/banner/05.png?v=1.0" alt="">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="360px"
      class="tc_div">
      <div>
        <img src="static/lvya/schoolFzpt/index/yyys4.png" alt="">
        <p>微信扫码获取更多产品资料</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      swiperOption1: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        loop: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },
    }
  },
  methods: {
    toLink(path) {
      this.dialogVisible = true
      // window.open('http://eos.lvya.org/', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
  .banner{
    max-width: 1920px;
    margin: 0 auto;
    line-height: 0;
  }
  .swiper1 img{
    width: 100%;
  }
  button{
    position: absolute;
    top: 60%;
    left: 20%;
    width: 110px;
    height: 35px;
    background: #0DC579;
    box-shadow: 0px 7px 21px 0px rgba(0,182,107,0.4100);
    border-radius: 5px;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
  /deep/.swiper-pagination-bullet-active{
    background:  #1BB876!important;
  }
  /deep/.swiper-pagination-bullet{
    background: #fff;
    opacity: 1;
  }
  /deep/.swiper-container-horizontal>.swiper-pagination-bullets{
    bottom: 20px;
  }
  .tc_div{
    text-align: center;
    p{
      margin-top: 20px;
    }
  }
</style>
