<template>
  <div class="webWrap">
    <div class="webCont">
      <div class="indexTitle">
        <p>以理念引领教育创新</p>
        <span></span>
      </div>
      <div class="bottom">
        <div class="left">
          <img src="static/lvya/home/index-cont2-img4.png" alt="" class="img4 wow animate__fadeInDownBig" data-wow-delay ="0s"/>
          <img src="static/lvya/home/index-cont2-img3.png" alt="" class="img3 wow animate__fadeInDownBig" data-wow-delay ="0.5s"/>
          <img src="static/lvya/home/index-cont2-img2.png" alt="" class="img2 wow animate__fadeInDownBig" data-wow-delay ="1s"/>
          <img src="static/lvya/home/index-cont2-img1.png" alt="" class="img1 wow animate__fadeInDownBig" data-wow-delay ="1.5s"/>
          <div class="img5Box">
            <img src="static/lvya/home/index-cont2-img5.png" alt="" class="img5 wow animate__fadeInDownLeft" data-wow-delay ="2s"/>
          </div>
          <div class="img6Box">
            <img src="static/lvya/home/index-cont2-img6.png" alt="" class="img6 wow animate__fadeInUpLeft" data-wow-delay ="2.5s"/>
          </div>
        </div>
        <div class="right">
          <div class="item wow animate__flipInX" data-wow-delay="2.8s">
            <div class="lineBox">
              <span></span>
              <div class="line"></div>
            </div>
            <p class="tit">持续质量监控诊改提升</p>
            <p class="des">隐形问题、过程问题、办学指标监控、教学指标监控......</p>
          </div>
          <div class="item wow animate__flipInX" data-wow-delay="3s" style="margin-top: 27px;">
            <div class="lineBox">
              <span></span>
              <div class="line"></div>
            </div>
            <p class="tit">信息技术保障高质量发展</p>
            <p class="des">领导驾驶舱、校情分析、年度分析报告、智能数据报告、诊改进度分析、决策支持服务</p>
          </div>
          <div class="item wow animate__flipInX" data-wow-delay="3.2s" style="margin-top: 32px;">
            <div class="lineBox">
              <span></span>
              <div class="line"></div>
            </div>
            <p class="tit">育人发展</p>
            <p class="des">课程建设、学生发展、教师发展</p>
          </div>
          <div class="item wow animate__flipInX" data-wow-delay="3.4s" style="margin-top: 52px;">
            <div class="lineBox">
              <span></span>
              <div class="line"></div>
            </div>
            <p class="tit">事务性管理</p>
            <p class="des">OA系统、教务系统、教学系统、科研系统、一卡通系统、校园监控系统、多媒体系统、宿舍系统......</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  },
}
</script>
<style lang="less" scoped>
.webWrap{
  overflow: hidden;
}
  .bottom{
    position: relative;
    padding-bottom: 25px;
    .left{
      position: relative;
      width: 580px;
      height: 545px;
      z-index: 10;
      img{
        position: absolute;
      }
      .img1{
        top: 0;
        left: 50%;
        margin-left: -103.5px;
      }
      .img2{
        top: 112px;
        left: 50%;
        margin-left: -139.5px;
      }
      .img3{
        top: 200px;
        left: 50%;
        margin-left: -200px;
      }
      .img4{
        bottom: -27px;
        left: 50%;
        margin-left: -277.5px;
      }
      .img5Box{
        position: absolute;
        left: 0;
        top: 0;
        width: 226px;
        height: 394px;
        overflow: hidden;
        .img5{
          opacity: 1;
          left: 0;
          top: 0;
        }
      }
      .img6Box{
        position: absolute;
        right: 0;
        top: 0;
        width: 222px;
        height: 395px;
        overflow: hidden;
        .img6{
          top: 0;
          left: 0;
          opacity: 1;
          transition: all 0.8s;
        }
      }
    }
    .right{
      position: absolute;
      right: 0;
      top: 0;
      width: 500px;
      padding-top: 66px;
      .item{
        position: relative;
        .tit{
          font-size: 21px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 5px;
        }
        .des{
          font-size: 16px;
          font-weight: 400;
          color: #696969;
          line-height: 1.6;
        }
        .lineBox{
          position: absolute;
          right: 102%;
          display: flex;
          top: 25px;
          flex-direction:row-reverse;
          span{
            display: block;
            width: 12px;
            height: 12px;
            background: #FFFFFF;
            border: 3px solid #19CA81;
            border-radius: 6px;
          }
          .line{
            width: 309px;
            height: 0px;
            box-sizing: border-box;
            border: 1px dashed #99AAC4;
            position: relative;
            top: 5px;
          }
        }
      }
    }
  }
</style>
