<template>
  <div class="webWrap indeCont3">
    <div class="webCont">
      <div class="indexTitle">
        <p>以技术深挖教育价值</p>
        <span></span>
      </div>
      <div class="box">
        <div class="left">
          <div class="item on">
            <div class="bg"><span></span></div>
            <p class="t">自定义表单</p>
            <p class="b">图形化用户界面，拖拽式操作，学校可灵活搭建教育应用。</p>
          </div>
          <div class="item">
            <div class="bg"><span></span></div>
            <p class="t">自定义流程</p>
            <p class="b">一站式开发，需求变化只需调整业务流程即可快速实现。</p>
          </div>
          <div class="item">
            <div class="bg"><span></span></div>
            <p class="t">软硬件自助接入</p>
            <p class="b">提供标准化软硬件接入规则，方便学校快速整合业务应用与智能硬件。</p>
          </div>
        </div>
        <div class="center">
          <div class="imgBox">
            <img src="static/lvya/home/index-cont3-img1.png" alt="" class="animate__animated animate__flipInY" style="display:block">
            <img src="static/lvya/home/index-cont3-img2.png" alt="" class="animate__animated animate__flipInX">
            <img src="static/lvya/home/index-cont3-img3.png" alt="" class="animate__animated animate__rotateIn">
            <img src="static/lvya/home/index-cont3-img4.png" alt="" class="animate__animated animate__zoomInDown">
            <img src="static/lvya/home/index-cont3-img5.png" alt="" class="animate__animated animate__bounceIn">
            <img src="static/lvya/home/index-cont3-img6.png" alt="" class="animate__animated animate__jackInTheBox">
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div class="bg"><span></span></div>
            <p class="t">业务中台</p>
            <p class="b">运用智慧引擎工具，精准覆盖教育场景，快速生成一站式服务大厅。</p>
          </div>
          <div class="item">
            <div class="bg"><span></span></div>
            <p class="t">数据中台</p>
            <p class="b">提供全过程数据管理、智能化数据应用、个性化数据诊断服务。</p>
          </div>
          <div class="item">
            <div class="bg"><span></span></div>
            <p class="t">数据驾驶舱</p>
            <p class="b">数据可视化管理，让学校管理者随时掌握学校发展动态，科学决策。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      indeCont3Index: 0,
      indeCont3Timer: ''
    }
  },
  methods: {
    startTimer() {
      this.indeCont3Timer = setInterval(this.changeIndex, 3000)
    },
    clear() {
      clearInterval(this.indeCont3Timer)
    },
    changeIndex() {
      if (this.indeCont3Index < 5) {
        this.indeCont3Index++
      } else {
        this.indeCont3Index = 0
      }
      this.changeImg(this.indeCont3Index)
    },
    changeImg(index) {
      $('.indeCont3 .box .item').removeClass('on').eq(index).addClass('on')
      $('.indeCont3 .center img').hide().eq(index).show()
    }
  },
  mounted() {
    let _this = this
    this.startTimer()
    $('.indeCont3 .box .item').hover(function() {
      let index = $('.indeCont3 .box .item').index(this)
      _this.indeCont3Index = index
      _this.changeImg(index)
      _this.clear()
    }, function() {
      _this.startTimer()
    })
  },
}
</script>
<style lang="less" scoped>
  .indeCont3{
    background: #f9f9f9;
    padding-bottom: 50px;
    .indexTitle{
      padding-bottom: 0;
      span{
        bottom: -15px;
      }
    }
  }
  .box{
    display: flex;
    // justify-content: space-between;
    align-items: center;
    justify-content: center;
    position: relative;
    .item{
      position: relative;
      width: 270px;
      box-sizing: border-box;
      padding: 10px 15px 10px 15px;
      cursor: pointer;
      .bg{
          position: absolute;
          top: 0;
          height: 100%;
          z-index: -1;
          width: 0;
        }
      p.t{
        font-size: 18px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 10px;
      }
      p.b{
        font-size: 14px;
        font-weight: 400;
        color: #696969;
        line-height: 1.8;
      }
      &.on,&:hover{
        p.t{
          color: #0DC579;
        }
        p.b{
          color: #0DC579;
        }
      }
    }
    .left{
      position: absolute;
      left: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      box-sizing: border-box;
      padding: 85px 0 90px;
      .item{
        .bg{
          left: 0;
          background: -webkit-linear-gradient(left, #e1f4eb , rgba(255,245,235,0)); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(right, #e1f4eb, rgba(255,245,235,0)); /* Opera 11.1 - 12.0 */
          background: -moz-linear-gradient(right, #e1f4eb, rgba(255,245,235,0)); /* Firefox 3.6 - 15 */
          background: linear-gradient(to right, #e1f4eb , rgba(255,245,235,0)); /* 标准的语法（必须放在最后） */
          span{
            left: 0;
            top: 0;
            display: none;
            position: absolute;
            width: 3px;
            height: 100%;
            background: #0DC579;
          }
        }
        &.on,&:hover{
          .bg{
            width: 100%;
            transition: all 0.5s;
            span{
              display: block;
            }
          }
        }
      }
    }
    .right{
      position: absolute;
      right: 0;
      text-align: right;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      box-sizing: border-box;
      padding: 85px 0 90px;
      .item{
        .bg{
          right: 0;
          background: -webkit-linear-gradient(right, #e1f4eb , rgba(255,245,235,0)); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(left, #e1f4eb, rgba(255,245,235,0)); /* Opera 11.1 - 12.0 */
          background: -moz-linear-gradient(left, #e1f4eb, rgba(255,245,235,0)); /* Firefox 3.6 - 15 */
          background: linear-gradient(to left, #e1f4eb , rgba(255,245,235,0)); /* 标准的语法（必须放在最后） */
          span{
            right: 0;
            top: 0;
            display: none;
            position: absolute;
            width: 3px;
            height: 100%;
            background: #0DC579;
          }
        }
        &.on,&:hover{
          .bg{
            width: 100%;
            transition: all 0.5s;
            span{
              display: block;
            }
          }
        }
      }
    }
    .center{
      position: relative;
      width: 885px;
      height: 585px;
      background: url('../../../../../public/static/lvya/home/computer.png') no-repeat center;
      background-size: 100% ;
      .imgBox{
        position: absolute;
        top: 92px;
        left: 141px;
        width: 606px;
        height: 400px;
        overflow: hidden;
      }
      img{
        display: none;
      }
    }
  }
</style>
