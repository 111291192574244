<template>
  <div>
    <Banner></Banner>
    <!-- <div class="center">
      <p><img src="static/lvya/home/index-text.png" alt="" class="img1"/></p>
      <p><img src="static/lvya/home/jt.png" alt="" class="jt animate-bounce-down"/></p>
    </div> -->
    <div class="newCont">
      <!-- <img src="static/lvya/home/index-cont1-img3.png" alt="" class="img"> -->
      <div class="w1200">
        <div class="left">
          <img src="static/lvya/home/newCont1.png" alt="">
        </div>
        <div class="right">
          <p class="p1">以<span>“新课标”</span>为基础，全面提升学生核心素养的未来教育解决方案</p>
          <p class="p2">建立新课标背景下“教、学、评”一体化实施路径<br/>输出学生立体成绩单和能力成绩单(MTC成绩单)<br/>学生成长可见、教师发展可见、学校发展可见的未来教育新形态</p>
          <p class="btn" @click="toLink()">查看详情</p>
        </div>
      </div>
    </div>
    <Cont1></Cont1>
    <Cont2></Cont2>
    <Cont3></Cont3>
  </div>
</template>
<script>
import Banner from './coms/Banner.vue'
import Cont1 from './coms/Cont1.vue'
import Cont2 from './coms/Cont2.vue'
import Cont3 from './coms/Cont3.vue'
export default {
  components: { Banner, Cont1, Cont2, Cont3 },
  methods: {
    toLink() {
      let origin = window.location.origin.includes('localhost') ? window.GateWayServer : window.location.origin
      window.open(origin + '/#/nhsfz', '_blank')
      // this.$router.push({
      //   path
      // })
    }
  },
}
</script>
<style lang="less" scoped>
  .center{
    padding: 60px 0 0;
    text-align: center;
  }
  .img1{
    margin-bottom: 25px;
  }
  @keyframes bounce-down {
    25% {transform: translateY(-5px);}
    50%{transform: translateY(0);}
    75% {transform: translateY(5px);}
    100% {transform: translateY(0);}
  }
  .animate-bounce-down{
    -webkit-animation: bounce-down 1s linear infinite;
    animation: bounce-down 1s linear infinite;
  }
  .newCont{
    width: 100%;
    height: 306px;
    background: url('../../../../public/static/lvya/home/bg.png') no-repeat;
    background-size: cover;
    .w1200{
      width: 1200px;
      height: 306px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .left{
        margin-right: 20px;
      }
      .right{
        .p1{
          color: #000000;
          font-size: 18px;
          font-weight: 600;
          span{
            color: #00BD6A;
          }
        }
        .p2{
          color: #696969;
          font-size: 14px;
          line-height: 30px;
          margin: 20px 0;
        }
        .btn{
          width: 100px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #fff;
          // padding: 5px 20px;
          background: #00BD6A;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
</style>
